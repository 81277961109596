import COLORS from 'const/colors';

const Globe = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666Z"
      stroke={COLORS.GRAY.G2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33325 8H14.6666"
      stroke={COLORS.GRAY.G2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99992 1.33325C9.66744 3.15882 10.6151 5.52794 10.6666 7.99992C10.6151 10.4719 9.66744 12.841 7.99992 14.6666C6.3324 12.841 5.38475 10.4719 5.33325 7.99992C5.38475 5.52794 6.3324 3.15882 7.99992 1.33325V1.33325Z"
      stroke={COLORS.GRAY.G2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Globe;
