const PoweredByHeadout = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.15 40" {...props}>
    <defs>
      <style>{'.cls-1{fill:#8000ff}'}</style>
    </defs>
    <g>
      <path
        d="M.25 0v40"
        style={{
          fill: 'none',
          stroke: '#888',
          strokeWidth: '.5px',
        }}
      />
      <path
        className="cls-1"
        d="M14.75 24.8c1.3-1.1 3.2-1.9 5.2-1.9s3.5.8 3.5 3.8v10.1h-2.7v-9.5c0-1.7-.7-2.2-2.1-2.2-1.5 0-2.9.7-4 1.4v10.3h-2.7V18.5h1.5c1 0 1.2.2 1.2 1.8l.1 4.5ZM28.25 30.4c.1 3.2 1.6 4.5 4.2 4.5 1.8 0 3-.6 3.8-1.2h.9v1.8c-1 .7-2.5 1.4-5 1.4-4.2 0-6.5-2.7-6.5-6.9s2.7-7.1 6.2-7.1 5.7 2.1 5.7 6.6v.9h-9.3Zm.1-1.7h6.5c0-3-1.2-4-3.1-4-1.8 0-3.1 1.1-3.4 4ZM47.25 35.2c-1 1.1-2.2 1.7-4.1 1.7-2.5 0-4.3-1.4-4.3-4.1s2.2-4.2 5.3-4.2c1.2 0 2 .1 3 .4v-1.8c0-1.6-.9-2.3-2.7-2.3-1.6 0-2.9.5-3.7 1.1h-.8v-2c1.2-.6 2.7-1.2 4.9-1.2 3.2 0 5.1 1.2 5.1 4.2v9.7h-1c-1.2 0-1.5-.3-1.7-1.5Zm-.1-1.7v-2.9c-.7-.1-1.6-.3-2.5-.3-1.8 0-3.1.7-3.1 2.3 0 1.7 1.1 2.4 2.5 2.4 1.4.1 2.3-.6 3.1-1.5ZM61.55 34.9c-.9 1.1-2.4 2-4.3 2-2.8 0-5.3-2-5.3-6.8 0-5.4 2.9-7.2 5.6-7.2 1.9 0 3 .6 3.9 1.4v-5.8h1.5c1 0 1.2.3 1.2 1.8v16.5h-1.8c-.5-.1-.7-.4-.8-1.9Zm0-1.8v-6.9c-.8-.7-1.7-1.2-3.1-1.2-1.8 0-3.7 1-3.7 5 0 3.7 1.4 5 3.2 5 1.5-.1 2.6-.8 3.6-1.9ZM66.35 29.9c0-4.4 2.8-7.1 6.5-7.1s6.5 2.7 6.5 7.1-2.8 6.9-6.5 6.9-6.5-2.5-6.5-6.9Zm10.3 0c0-3.3-1.5-5.1-3.8-5.1s-3.8 1.8-3.8 5.1 1.5 4.9 3.8 4.9c2.4.1 3.8-1.6 3.8-4.9ZM90.25 33.5V23.1h2.6v13.7h-1.7c-.6 0-.9-.2-1-1.5-1.3 1-3.1 1.7-4.9 1.7-2.1 0-3.7-.9-3.7-3.6V23.1h2.7v9.6c0 1.8.9 2.2 2.1 2.2 1.4-.1 2.8-.7 3.9-1.4ZM94.75 23h1.6v-3.7h1.6c.8 0 1.1.3 1.1 1.5V23h3v1.9h-3v8c0 1.5.6 1.9 1.9 1.9h1.2v1.7c-.5.1-1.2.3-2.2.3-2.3 0-3.7-1-3.7-3.5v-8.4h-1.6l.1-1.9Z"
      />
      <path
        d="M13.15 1.1h1.2v5.3c.3-.4.7-.7 1.1-.9.4-.2.9-.3 1.4-.3s1 .1 1.5.3c.5.2.8.4 1.2.8.3.3.6.7.7 1.2.2.4.3.9.3 1.4s-.1 1-.3 1.4-.4.8-.7 1.2c-.3.3-.7.6-1.1.8-.4.2-.9.3-1.4.3-.6 0-1.2-.1-1.6-.4-.5-.3-.8-.6-1-1v1.2h-1.2V1.1h-.1Zm1.1 7.8c0 .4.1.7.2 1 .1.3.3.6.5.8s.5.4.8.5.6.2 1 .2.7-.1 1-.2c.3-.1.6-.3.8-.5s.4-.5.5-.8.2-.7.2-1-.1-.7-.2-1c-.1-.3-.3-.6-.5-.8-.2-.2-.5-.4-.8-.5s-.6-.2-1-.2-.7.1-1 .2-.5.3-.8.5c-.2.2-.4.5-.5.8-.1.3-.2.6-.2 1Zm10.7 5c-.1.3-.2.6-.4.8-.1.2-.3.5-.4.6-.2.2-.4.3-.6.4-.2.1-.5.1-.9.1h-.5c-.2 0-.3-.1-.5-.1l.1-1c.1 0 .2.1.4.1h.4c.4 0 .6-.1.8-.3s.3-.5.5-.8l.5-1.3-2.9-7h1.3l2.1 5.4 2-5.4h1.3l-3.2 8.5Z"
        style={{
          fill: '#888',
        }}
      />
    </g>
  </svg>
);
export default PoweredByHeadout;
